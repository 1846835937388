import { useState } from 'react'
import PropTypes from 'prop-types'
import HeaderSearchBar from 'shared/components/layout/Header/HeaderSearchBar'

type SearchProps = {
  className?: string
  isTransparent?: boolean
  placeholder?: string
  icon?: any
  currentSearchText?: string
  onSearch?: (text: string) => void
}

const Search = ({
  className,
  isTransparent = false,
  placeholder = `Try "Dreamy" artworks`,
  icon,
  currentSearchText,
  onSearch,
}: SearchProps) => {
  // states
  const [isOpenSearch, setIsOpenSearch] = useState(false)

  return (
    <div className={className}>
      {isOpenSearch ? (
        // search input
        <HeaderSearchBar
          screen="homepage"
          placeholder={placeholder}
          isTransparent={isTransparent}
          currentSearchText={currentSearchText}
          onChange={onSearch}
        />
      ) : (
        // search icon
        <div className="cursor-pointer" onClick={() => setIsOpenSearch(true)}>
          {icon}
        </div>
      )}
    </div>
  )
}

Search.propTypes = {
  className: PropTypes.string,
  isTransparent: PropTypes.bool,
  placeholder: PropTypes.string,
  icon: PropTypes.node,
  currentSearchText: PropTypes.string,
  onSearch: PropTypes.func,
}

export default Search
